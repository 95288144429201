// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-collections-jsx": () => import("./../../src/pages/collections.jsx" /* webpackChunkName: "component---src-pages-collections-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-flowers-jsx": () => import("./../../src/pages/flowers.jsx" /* webpackChunkName: "component---src-pages-flowers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-online-jsx": () => import("./../../src/pages/order-online.jsx" /* webpackChunkName: "component---src-pages-order-online-jsx" */)
}

